var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "error-page" }, [
    _c(
      "div",
      { staticClass: "user-wrapper", on: { click: _vm.handleLogoutClick } },
      [
        _c("label", [_vm._v(_vm._s(_vm.getUserName()))]),
        _c("label", [_vm._v("|")]),
        _c("label", { staticClass: "logoutBtn" }, [_vm._v("退出")])
      ]
    ),
    _c("div", { staticClass: "content-con" }, [
      _c("img", { attrs: { src: _vm.src, alt: "404" } }),
      _c("h5", [_vm._v(_vm._s(_vm.desc))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }